import { useEffect, useCallback, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { ResourceForm } from "components/ResourceForm/ResourceForm";
import {
	saveKeyPersonnelChangesForRfx,
	getPastKeyPersonnelForRfx,
} from "actions/RFQ/Supplier/rfqActions";
import { Box, Flex } from "@chakra-ui/react";
import { Button } from "components/Button";
import { useQuery } from "@tanstack/react-query";
import { SmartFieldCancelModal } from "./_components/SmartFieldCancelModal";
import { useSmartFieldEditor } from "../SmartFieldEditor/hooks/useSmartFieldEditor";
import {
	LoadingView,
	ErrorView,
	NoDataView,
} from "../SmartFieldEditor/_components/SmartFieldStatusViews";
import { useSmartFieldScroll } from "../SmartFieldEditor/hooks/useSmartFieldScroll";

export const SmartFieldKeyPersonnelEditorComponent = ({
	keyPersonnelId,
	rfxId,
	companyId,
	...props
}) => {
	const {
		isModalOpen,
		isModalSaving,
		setIsModalSaving,
		isInModalFlow,
		setIsInModalFlow,
		errorStatus,
		setErrorStatus,
		isSaving,
		setIsSaving,
		autoSaveDisabled,
		setAutoSaveDisabled,
		navigateToReturnableSchedules,
		handleCancelClick,
		handleModalClose,
	} = useSmartFieldEditor({
		id: keyPersonnelId,
		rfxId,
		companyId,
		entityType: "KeyPersonnel",
	});

	const { restoreScrollPosition } = useSmartFieldScroll("keyPersonnel");

	// useRef for formik instance
	const formikRef = useRef(null);

	// Store the current values for direct API calls
	const currentValuesRef = useRef(null);

	const {
		data: keyPersonnelData,
		isLoading,
		error: keyPersonnelError,
	} = useQuery({
		queryKey: ["pastKeyPersonnelForRfx", { keyPersonnelId, rfqId: rfxId }],
		queryFn: getPastKeyPersonnelForRfx,
		enabled: !!keyPersonnelId && !!rfxId,
	});

	const initialValues = useMemo(() => {
		if (!keyPersonnelData) return null;

		// Handle projects_completed - could be array or object
		let projectsData = [];
		if (keyPersonnelData.projects_completed) {
			if (Array.isArray(keyPersonnelData.projects_completed)) {
				// Direct array case
				projectsData = keyPersonnelData.projects_completed;
			} else if (keyPersonnelData.projects_completed.data) {
				// {data: [...]} format case
				projectsData = keyPersonnelData.projects_completed.data;
			}
		}

		// Special handling for Resume
		let resumeValue = "";
		if (keyPersonnelData.resume) {
			if (
				Array.isArray(keyPersonnelData.resume) &&
				keyPersonnelData.resume.length > 0
			) {
				// convert to structure used in EditResource.js
				resumeValue = {
					resume_file: {
						path: keyPersonnelData.resume[0],
						name: keyPersonnelData.resume[1] || "",
					},
				};
			} else if (typeof keyPersonnelData.resume === "string") {
				resumeValue = {
					resume_file: {
						path: keyPersonnelData.resume,
						name: "",
					},
				};
			}
		}

		return {
			first_name: keyPersonnelData.first_name || "",
			last_name: keyPersonnelData.last_name || "",
			email: keyPersonnelData.email || "",
			position: keyPersonnelData.position || "",
			experience: keyPersonnelData.experience?.toString() || "1",
			sector_ids: Array.isArray(keyPersonnelData.sectors)
				? keyPersonnelData.sectors.map((sector) => sector.id)
				: [],
			projects_completed: projectsData,
			resume: resumeValue,
			resume_file: null,
			avatar_image: keyPersonnelData.avatar_location || "",
			company_id: keyPersonnelData.company_id || companyId,
			status: "published",
			roles: "default",
		};
	}, [keyPersonnelData, companyId]);

	// Update currentValuesRef when initialValues change
	useEffect(() => {
		if (initialValues) {
			currentValuesRef.current = initialValues;
		}
	}, [initialValues]);

	// Update the navigation function to handle the new scroll data structure
	const navigateWithScrollRestore = useCallback(() => {
		restoreScrollPosition(navigateToReturnableSchedules);
	}, [restoreScrollPosition, navigateToReturnableSchedules]);

	// Update makeDirectApiCall to use the new navigation function
	const makeDirectApiCall = useCallback(
		(values) => {
			setIsSaving(true);

			return saveKeyPersonnelChangesForRfx(keyPersonnelId, rfxId, values)
				.then((result) => {
					navigateWithScrollRestore();
					return result;
				})
				.catch((error) => {
					throw error;
				})
				.finally(() => {
					setIsSaving(false);
					setIsModalSaving(false);
				});
		},
		[
			keyPersonnelId,
			rfxId,
			navigateWithScrollRestore,
			setIsSaving,
			setIsModalSaving,
		]
	);

	const handleSaveButtonClick = useCallback(() => {
		setIsSaving(true);

		// get current form values, fall back to initial values if not available
		const values = currentValuesRef.current || initialValues;

		// make direct API call with the values
		makeDirectApiCall(values).catch(() => {
			setIsSaving(false);
		});
	}, [makeDirectApiCall, initialValues, setIsSaving]);

	const handleSaveFromModal = useCallback(() => {
		setIsModalSaving(true);

		const values = currentValuesRef.current || initialValues;
		makeDirectApiCall(values)
			.catch(() => {
				setIsModalSaving(false);
			})
			.finally(() => {
				setIsInModalFlow(false);
			});
	}, [makeDirectApiCall, initialValues, setIsModalSaving, setIsInModalFlow]);

	const handleSubmit = useCallback(
		async (values, { setSubmitting, setErrors, setStatus }) => {
			// update current form values
			currentValuesRef.current = values;

			// only execute API call if not in modal flow or explicitly saving
			if (!isInModalFlow || isModalSaving) {
				try {
					await makeDirectApiCall(values);
				} catch (error) {
					if (error.errors) {
						setErrors(error.errors);
					}

					setStatus("Error");
					setErrorStatus(true);
				}
			}

			// reset modal flow flag if modal is closed
			if (!isModalOpen) {
				setIsInModalFlow(false);
			}

			setSubmitting(false);
		},
		[
			makeDirectApiCall,
			isInModalFlow,
			isModalSaving,
			isModalOpen,
			setErrorStatus,
			setIsInModalFlow,
		]
	);

	if (isLoading) {
		return <LoadingView entityType="key personnel" />;
	}

	if (keyPersonnelError) {
		return (
			<ErrorView
				error={keyPersonnelError}
				entityType="key personnel"
				onNavigate={navigateWithScrollRestore}
			/>
		);
	}

	if (!initialValues) {
		return (
			<NoDataView
				entityType="key personnel"
				onNavigate={navigateWithScrollRestore}
			/>
		);
	}

	return (
		<Box p={4}>
			<ResourceForm
				initialValues={initialValues}
				handleSubmit={handleSubmit}
				isRequesting={isSaving || isModalSaving}
				editForm={true}
				autoUpdateForm={autoSaveDisabled}
				setAutoUpdateForm={setAutoSaveDisabled}
				errorStatus={errorStatus}
				setErrorStatus={setErrorStatus}
				supplierSmartField={true}
				hideDefaultButtons={true}
				innerRef={(formik) => {
					formikRef.current = formik;
					// When formik is available, update our current values reference
					if (formik) {
						currentValuesRef.current = formik.values;
					}
				}}
				customButtons={
					<Flex
						justifyContent="flex-end"
						gap={4}
						mt={6}
					>
						<Button
							btntype="link"
							onClick={handleCancelClick}
						>
							Cancel
						</Button>
						<Button
							type="button"
							isLoading={isSaving}
							onClick={handleSaveButtonClick}
						>
							Save Changes to RFX
						</Button>
					</Flex>
				}
			/>
			<SmartFieldCancelModal
				isOpen={isModalOpen}
				onClose={handleModalClose}
				onDiscard={navigateWithScrollRestore}
				onSave={handleSaveFromModal}
				isSaving={isModalSaving}
			/>
		</Box>
	);
};

const mapStateToProps = (state) => {
	return {
		companyId: state.search.activeCompany.id,
	};
};

export const SmartFieldKeyPersonnelEditor = connect(mapStateToProps)(
	SmartFieldKeyPersonnelEditorComponent
);

export default SmartFieldKeyPersonnelEditor;
